<template>
  <div class="home">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">
            <div class="deviceBack">
              <i class="el-icon-arrow-left" @click="back()"></i>
            </div>
            <span>用户信息</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="personMain">
          <div class="personTil">用户信息</div>
          <el-form
            :model="dataForm"
            ref="dataForm"
            class="registerForm"
            label-width="68px"
            status-icon
          >
            <el-form-item prop="name" label="姓名：">
              <el-input v-model="dataForm.name" disabled placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item prop="mobile" label="电话：">
              <el-input
                v-model="dataForm.mobile"
                disabled
                placeholder="请输入电话"
              />
            </el-form-item>
<!--            <el-form-item prop="idcard" label="身份证：">-->
<!--              <el-input-->
<!--                v-model="dataForm.idcard"-->
<!--                placeholder="请输入身份证"-->
<!--                disabled-->
<!--              />-->
<!--            </el-form-item>-->
            <el-form-item prop="sex" label="性别：">
              <el-radio-group v-model="dataForm.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
                <el-radio :label="0">未知</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="address" label="地址：">
              <el-input
                v-model="dataForm.address"
                rows="5"
                type="textarea"
                placeholder="请输入地址"
              />
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            class="lineBtn"
            :loading="btnloading"
            @click="dataFormSubmit"
          >确认修改</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      btnloading: false,  // 点击提交时，防止多次触碰，设定加载规则
      state: null,
      cemBuyerList: [], //墓位信息合集
      dataForm: {
        id: 0,
        account:"",
        name: "",
        mobile: "",
        idcard: "",
        sex: 0,
        address: "",
        buyerId:"",
      },
    }
  },
  mounted() {
    this.getDateList();
  },
  methods: {
    getDateList() {
      this.dataForm = JSON.parse(sessionStorage.getItem("store"));
    },
    // 返回上一页
    back() {
      this.$router.replace({ name: "mine" });
    },
    // 提交
    dataFormSubmit() {
      this.$http.httpPost(`/mobile/userInfo/update`, {
        id: this.dataForm.id,
        name: this.dataForm.name,
        account: this.dataForm.account,
        address: this.dataForm.address,
        mobile: this.dataForm.mobile,
        idcard: this.dataForm.idcard,
        sex: this.dataForm.sex,
        buyerId: this.dataForm.buyerId,
      }).then( data => {
        if (data && data.code === 0) {
          this.$store.commit("updateId", this.dataForm.id);
          this.$store.commit("updateUserAccount", this.dataForm.account);
          this.$store.commit("updateUserName", this.dataForm.name);
          this.$store.commit("updateSex", this.dataForm.sex);
          this.$store.commit("updateMobile", this.dataForm.mobile);
          this.$store.commit("updateBuyerid", this.dataForm.buyerId);
          this.$store.commit("updateIdcard", this.dataForm.idcard);
          this.$store.commit("updateAddress", this.dataForm.address);
          this.$message.success({
            type: 'success',
            message: '修改成功!',
            duration: 1500,
            onClose: () => {
              this.$router.replace({ name: "mine" });
            },
          })
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .home::v-deep {
    height: 100%;
    .el-container {
      height: 100%;
      .el-main{
        padding: 10px 15px !important;
        background-color: #efefef;
        .personMain {
          position: relative;
          background-color: #fff;
          padding: 10px;
          border-radius: 4px;
          .personTil {
            font-size: 13px;
            color: #666;
            text-align: center;
            padding: 0 0 10px;
            margin: 0 0 15px;
            border-bottom: 1px #efefef solid;
          }
          .registerForm {
            position: relative;
            .el-form-item {
              margin: 0 0 15px;
              .el-form-item__label {
                font-size: 12px;
                padding: 0 8px 0 0;
                line-height: 32px;
              }
              .el-form-item__content {
                line-height: 32px;
                .el-input {
                  font-size: 12px;
                  .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                  }
                }
                .el-radio-group {
                  .el-radio {
                    font-size: 12px;
                    margin-right: 18px;
                    .el-radio__input {
                      .el-radio__inner {
                        width: 12px;
                        height: 12px;
                      }
                    }
                    .el-radio__label {
                      font-size: 12px;
                      padding-left: 5px;
                    }
                  }
                }
                .el-textarea {
                  font-size: 12px;
                  .el-textarea__inner {
                    padding: 5px 8px;
                  }
                }
              }
            }
          }
          .lineBtn {
            width: 100%;
            margin: 10px 0;
            font-size: 13px;
          }
        }
        .addressCon {
          position: relative;
          margin: 20px 0 0;
          background-color: #fff;
          padding: 10px;
          border-radius: 4px;
          .addressTil {
            font-size: 13px;
            color: #666;
            text-align: center;
            padding: 0 0 10px;
            border-bottom: 1px #efefef solid;
          }
          .addressBlock {
            position: relative;
            padding: 10px 0;
            .fourList {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              .addLine {
                font-size: 13px;
                line-height: 1.4;
                padding: 5px 0;
                flex: 0 0 25%;
              }
            }
            .nameLine {
              font-size: 13px;
              line-height: 1.4;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
</style>
